/** контроллеры в сервисе mobile bff */
export enum MobileBffControllerEnum {
  Auth = 'auth',
  Customer = 'customer',
  Rent = 'rent',
  Vehicle = 'vehicle',
  Locales = 'locales',
  Catalog = 'catalog',
  Maintenance = 'maintenance',
  Notification = 'notification',
  VehicleInspection = 'vehicle-inspection',
  Content = 'content',
}
