import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmGetAvailableCatalogTariffsResponseDto } from './dtos';

/**
 * ### Получение количества доступных автомобилей из каталога по тарифам
 * basePath: /crm
 * controller: /catalog
 * path: 'tariffs/:modificationId/available'
 * method: GET
 * fullPath: /crm/catalog/tariffs/:modificationId/available
 */
export namespace CrmGetAvailableCatalogTariffsEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Catalog;
  export const endPointPath = 'tariffs/:modificationId/available';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Каталог доступных автомобилей
   */
  export type ResponseData = PaginationInterface<CrmGetAvailableCatalogTariffsResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    modificationId: string;
  };

  /** Параметры запроса: пагинация */
  export type RequestQueryParams = Record<string, string | number>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
