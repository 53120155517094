import { ModelFilters, ModelSorting } from 'amqp-contracts';
import { VehicleModelEntity } from 'backend-entity';

import {
  HttpMethod,
  joinPath,
  PaginationInterface,
  PaginationRequest,
  ResponseType,
  SortingRequest,
} from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';

/**
 * ### Получение списка моделей с пагинацией
 * basePath: /crm
 * controller: /vehicle
 * path: ''
 * method: GET
 * fullPath: /crm/vehicle-model
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleModelFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleModel;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<VehicleModelEntity>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams extends PaginationRequest, SortingRequest<ModelSorting['field']>, ModelFilters {}

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
