export interface MobileBffCreateRentReservationRequest {
  /** Дата начала аренды */
  startDate: Date;
  /** Дата окончания аренды */
  endDate?: Date;
  /** Идентификатор конфигурации авто */
  configurationId: string;
  /** Идентификатор тарифа */
  tariffId: string;
  /** Идентификатор пункта выдачи */
  issuePointId: string;
}
