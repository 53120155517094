import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { QuestionModel } from '../../mobile-bff';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmPatchQuestionRequestDto } from './dtos';

/**
 * ### Обновление вопроса
 * basePath: /crm
 * controller: /question
 * path: ':questionId'
 * method: PATCH
 * fullPath: /crm/question/:questionId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmPatchQuestionEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Question;
  export const endPointPath = ':questionId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ
   */
  export type ResponseData = QuestionModel;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    questionId: string;
  };

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmPatchQuestionRequestDto;

  export enum ErrorCodes {}
}
