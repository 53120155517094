import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';
import { InsuranceEntity } from 'backend-entity';

/**
 * ### Получение страховки по идентификатору
 * basePath: /crm
 * controller: /vehicle
 * path: 'insurance/:insuranceId'
 * method: GET
 * fullPath: /crm/vehicle/insurance/:insuranceId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleInsuranceGetByIdEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = 'insurance/:insuranceId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = InsuranceEntity;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    insuranceId: string;
  };

  /** Параметры запроса */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
