import { CrmLocaleHeaders, HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Создание точки выдачи авто
 * basePath: /crm
 * controller: /maintenance
 * path: 'delivery-point'
 * method: POST
 * fullPath: /crm/maintenance/delivery-point
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmSaveDeliveryPointEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Maintenance;
  export const endPointPath = 'delivery-point';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = void;
  export type Response = null;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = null;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки */
  export type RequestHeaders = CrmLocaleHeaders;

  /** Тело запроса */
  export type RequestBody = {
    /** Активность точки */
    active: boolean;
    /** Адрес точки */
    fullAddress: string;
    /** Город */
    city: string;
    /** Расписание */
    schedule: string;
    /** Широта */
    latitude: number;
    /** Долгота */
    longitude: number;
    /** Радиус действия точки */
    deliveryRadius: number;
  };

  export enum ErrorCodes {}
}
