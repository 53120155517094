export interface FineModel {
  /** VIN авто */
  vin: string;
  /** номер постановления */
  billId: string;

  /** Размер скидки в процентах */
  discountSize: number;
  /** Стоимость штрафа без скидок */
  paymentAmount: number;
  /** Стоимость штрафа со скидкой*/
  paymentAmountWithDiscount: number;

  /** Дата выставления штрафа */
  billDate: Date;
  /** Дата совершения штрафа */
  offenseDateTime: Date;
  /** Срок до которого действует скидка */
  discountDate: Date;
  /** срок до которого нужно оплатить штраф */
  paymentDue: Date;

  koapText: string;
  koapCode: string;
  location: string;
  longitude: number;
  latitude: number;
  paymentIssuerName: string;
  paymentReceiverName: string;
  photos: string[];
}
