import { CrmException } from 'amqp-contracts';

import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmUserDto, CrmUserPostRequestDto } from './dtos';

/**
 * ### Создание пользователя CRM
 * basePath: /crm
 * controller: /user
 * path: '/'
 * method: POST
 * fullPath: /crm/user
 */

export namespace CrmUserPostEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.User;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: Пользователь
   */
  export type ResponseData = CrmUserDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmUserPostRequestDto;

  /** Коды ошибок */
  export type ErrorKeys = CrmException.UserAlreadyExists | CrmException.RoleNotFound;
}
