import { OrderEntity, OverspendingOrderPayload, VehicleEntity } from 'backend-entity';

import { FineModel } from '../models';

export type FineOrderBasePayload = Pick<VehicleEntity, 'vin' | 'license' | 'brand' | 'model'> & {
  issueYear: number | null;
};

export type FineOverspendingOrderPayload = FineOrderBasePayload & OverspendingOrderPayload;

export type FineGibddOrderPayload = FineOrderBasePayload &
  Pick<
    FineModel,
    | 'billId'
    | 'offenseDateTime'
    | 'koapText'
    | 'koapCode'
    | 'location'
    | 'longitude'
    | 'latitude'
    | 'paymentIssuerName'
    | 'paymentReceiverName'
    | 'photos'
  >;

export type FineOrderPayload = FineOverspendingOrderPayload | FineGibddOrderPayload | FineOrderBasePayload;

export type FineOrderItemModel<T = FineOrderBasePayload> = Pick<
  OrderEntity,
  'id' | 'rentId' | 'createdAt' | 'type' | 'status' | 'totalPrice' | 'paidAt' | 'paidDue'
> & {
  payload: T;
  date: Date;
};

export type MobileBffGetRentFineOrdersResponse = {
  rows: FineOrderItemModel<FineOrderPayload>[];
  total: number;
};
