import { ConfigurationImageEntity } from 'backend-entity';

import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';

/**
 * ### Добавление фото
 * basePath: /crm
 * controller: /vehicle
 * post: 'image'
 * method: PATCH
 * fullPath: /crm/vehicle-configuration/image
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleConfigurationImageAddEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleConfiguration;
  export const endPointPath = 'image';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ
   */
  export type ResponseData = null;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {};

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = ConfigurationImageEntity;

  export enum ErrorCodes {}
}
