import {
  ConfigurationEntity,
  ModificationEntity,
  TariffEntity,
  VehicleBrandEntity,
  VehicleModelEntity,
} from 'backend-entity';

export type CatalogItemBrand = Pick<VehicleBrandEntity, 'id' | 'name'>;
export type CatalogItemModel = Pick<VehicleModelEntity, 'id' | 'name'>;
export type CatalogItemModification = Omit<
  ModificationEntity,
  'createdAt' | 'updatedAt' | 'tariffs' | 'modificationDocPath'
> & {
  modificationDocUrl: string | null;
};
export type CatalogItemConfiguration = Pick<ConfigurationEntity, 'id' | 'name' | 'images' | 'color'> & {
  tariffs: CatalogItemTariff[];
};
export type CatalogItemTariff = TariffEntity & {
  available: boolean;
  price: number;
};

export interface MobileBffGetCatalogItemResponseDto {
  brand: CatalogItemBrand;
  model: CatalogItemModel;
  modification: CatalogItemModification;
  configurations: CatalogItemConfiguration[];
}
