import { CrmException } from 'amqp-contracts';

import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmUserDto, CrmUserPatchRequestDto } from './dtos';

/**
 * ### Обновление пользователя CRM
 * basePath: /crm
 * controller: /user
 * path: '/:id'
 * method: PATCH
 * fullPath: /crm/user/:id
 */

export namespace CrmUserPatchEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.User;
  export const endPointPath = ':id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: Пользователь
   */
  export type ResponseData = CrmUserDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * * id - ID пользователя CRM
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    id: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmUserPatchRequestDto;

  /** Коды ошибок */
  export type ErrorKeys = CrmException.UserNotFound | CrmException.RoleNotFound;
}
