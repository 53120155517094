import { CrmLocaleHeaders, HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

import { DeliveryPoint } from 'backend-entity';

/**
 * ### Создание точки выдачи авто
 * basePath: /crm
 * controller: /maintenance
 * path: 'delivery-point'
 * method: PATCH
 * fullPath: /crm/maintenance/delivery-point
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmUpdateDeliveryPointEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Maintenance;
  export const endPointPath = 'delivery-point';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ
   */
  export type ResponseData = DeliveryPoint;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = null;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки */
  export type RequestHeaders = CrmLocaleHeaders;

  /** Тело запроса: отсутствует */
  export type RequestBody = {
    id: string;
    /** Активность точки */
    active?: boolean;
    /** Адрес точки */
    fullAddress?: string;
    /** Город */
    city?: string;
    /** Расписание */
    schedule?: string;
    /** Широта */
    latitude?: number;
    /** Долгота */
    longitude?: number;
    /** Радиус действия точки */
    deliveryRadius?: number;
  };

  export enum ErrorCodes {}
}
