import { DocumentType, ReportType } from 'backend-entity';

export interface MobileBffGetRetryInfoResponseDto {
  documents: {
    documentType: DocumentType;
    path: string;
  }[];
  requiredDocuments: DocumentType[];
  reportType: ReportType;
}
