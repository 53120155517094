import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { LocalesEnum } from './enums';

import { DeliveryPoint } from 'backend-entity';

/**
 * ### Получение точек выдачи авто
 * basePath: /mobile-bff
 * controller: /maintenance
 * path: 'delivery-point'
 * method: GET
 * fullPath: /mobile-bff/maintenance/delivery-point
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MobileFindDeliveryPointsEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Maintenance;
  export const endPointPath = 'delivery-point';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<DeliveryPoint>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: 'id';
    sortDirection?: 'asc' | 'desc';

    /** Адрес точки */
    fullAddress?: string;
    /** Город */
    city?: string;
    /** Расписание */
    schedule?: string;
    /** Широта */
    latitude?: number;
    /** Долгота */
    longitude?: number;
    /** Радиус действия точки */
    deliveryRadius?: number;
  }

  /** Заголовки */
  export type RequestHeaders = {
    'x-language': LocalesEnum;
  };

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
