import { VehicleDeviceEntity } from 'backend-entity';

export type CreateDeviceRequiredFields = Pick<
  VehicleDeviceEntity,
  'deviceId' | 'activationStatus' | 'type' | 'deviceSpecific'
>;
export type CreateDeviceOptionalFields = Partial<
  Pick<VehicleDeviceEntity, 'firstActivationDate' | 'activationDate' | 'deactivationDate'>
>;

export type CrmDeviceMisosPostRequestDto = CreateDeviceRequiredFields & CreateDeviceOptionalFields;
