import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule, MatIconModule],
  exports: [MatIconModule],
})
export class SvgIconModule {
  icons: string[] = [
    'external',
    'drop-down-arrow',
    'arrow',
    'shevron',
    'close',
    'copy',
    'documents-done',
    'edit',
    'info',
    'person',
    'search',
    'done',
    'timeline-arrow',
    'refresh',
    'start-marker',
    'star',
    'burger',
    'truck-delivery',
    'help-circle',
    'settings',
  ];

  constructor(registry: MatIconRegistry, ds: DomSanitizer) {
    this.icons.forEach((icon) =>
      registry.addSvgIcon(icon, ds.bypassSecurityTrustResourceUrl(`/assets/icons/svg/${icon}.svg`)),
    );
  }
}
