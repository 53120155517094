import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { AppointmentEntity, AppointmentStatus } from 'backend-entity';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Получения списка записей на ТО
 * basePath: /crm
 * controller: /vehicle-inspection
 * path: /appointment
 * method: GET
 * fullPath: /crm/vehicle-inspection/appointment
 */
export namespace CrmFindInspectionAppointmentEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleInspection;
  export const endPointPath = 'appointment';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список машин
   */
  export type ResponseData = PaginationInterface<AppointmentEntity>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * onPage - Размер страницы
   * * page - Запрошенная страница
   * */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: string;
    sortDirection?: 'asc' | 'desc';

    appointmentId?: string;
    rentId?: string;
    customerId?: string;
    vehicleId?: string;
    status?: AppointmentStatus;
    statusIn?: AppointmentStatus[];
  }

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
