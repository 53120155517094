/** контроллеры в сервисе crm */
export enum CrmControllersEnum {
  Auth = 'auth',
  User = 'user',
  Customer = 'customer',
  Vehicle = 'vehicle',
  VehicleBrand = 'vehicle-brand',
  VehicleModel = 'vehicle-model',
  VehicleColor = 'vehicle-color',
  VehicleModification = 'vehicle-modification',
  VehicleConfiguration = 'vehicle-configuration',
  VehicleInspection = 'vehicle-inspection',
  Storage = 'storage',
  Rent = 'rent',
  Tariff = 'tariff',
  Device = 'device',
  Catalog = 'catalog',
  Maintenance = 'maintenance',
  CheckList = 'check-list',
  Scoring = 'scoring',
  Notification = 'notification',
  Question = 'question',
}
