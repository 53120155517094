import { SidenavItem } from './sidenav-item.model';

export const SIDENAV_MENU_ITEMS: SidenavItem[] = [
  new SidenavItem({
    icon: 'home',
    name: 'Главная',
    route: '/',
    routerLinkActiveOptions: { exact: true },
    position: 0,
  }),
  new SidenavItem({
    icon: 'person',
    name: 'Клиенты',
    route: '/customers',
    position: 0,
  }),
  new SidenavItem({
    icon: 'directions_car',
    name: 'Автопарк',
    route: '/vehicles',
    position: 0,
  }),
  new SidenavItem({
    icon: 'settings',
    name: 'Конфигуратор',
    route: '/configurator',
    position: 0,
  }),
  new SidenavItem({
    icon: 'content_copy',
    name: 'Аренды',
    route: '/rents',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'documents-done',
    name: 'Заявки',
    route: '/vehicle-inspections',
    position: 0,
  }),
  new SidenavItem({
    icon: 'grid_view',
    name: 'Тарифы',
    route: '/tariffs',
    position: 0,
  }),
  new SidenavItem({
    icon: 'payments',
    name: 'Начисления',
    route: '/orders',
    position: 0,
  }),
  new SidenavItem({
    icon: 'done',
    name: 'Чек-листы',
    route: '/check-lists',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'truck-delivery',
    name: 'Точки выдачи',
    route: '/delivery-points',
    position: 0,
  }),
  new SidenavItem({
    svgIcon: 'help-circle',
    name: 'Помощь',
    route: '/faq',
    position: 0,
  }),
  new SidenavItem({
    icon: 'settings',
    name: 'Настройки',
    route: '/settings',
    position: 0,
    disabled: true,
  }),
];
