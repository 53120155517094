import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmStorageGetFilesResponseDto } from './dtos';

/**
 * ### Получение ссылок на файлы
 * basePath: /crm
 * controller: /storage
 * path: '/files'
 * method: GET
 * fullPath: /crm/storage/files
 */

export namespace CrmStorageGetFilesEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Storage;
  export const endPointPath = 'files';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список url файлов
   */
  export type ResponseData = CrmStorageGetFilesResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * paths - пути файлов
   * */
  export interface RequestQueryParams extends Record<string, string | number | undefined | string[]> {
    paths: string[];
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
