import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { QuestionModel } from '../../mobile-bff';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmCreateQuestionRequestDto } from './dtos';

/**
 * ### Создание вопроса
 * basePath: /crm
 * controller: /question
 * post: ''
 * method: POST
 * fullPath: /crm/question
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmCreateQuestionEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Question;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = QuestionModel;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {};

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmCreateQuestionRequestDto;

  export enum ErrorCodes {}
}
