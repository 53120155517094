import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetActiveQuestionsResponse } from './dtos';

/**
 * ### Получение активных вопросов FAQ
 * basePath: /mobile-bff
 * controller: /content
 * path: 'faq'
 * method: GET
 * fullPath: /mobile-bff/content/faq
 */
export namespace MobileBffGetActiveQuestionsEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Content;
  export const endPointPath = 'faq';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: не законченная аренда
   */
  export type ResponseData = MobileBffGetActiveQuestionsResponse;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
