import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { PushHistoryModel } from './models';

/**
 * ### Отправка заявки на авто
 * basePath: /mobile-bff
 * controller: /notification
 * path: 'send-vehicle-request'
 * method: POST
 * fullPath: /mobile-bff/notification/push-history
 */
export namespace MobileBffSendVehicleRequestEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Notification;
  export const endPointPath = 'send-vehicle-request';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ: не законченная аренда
   */
  export type ResponseData = PaginationInterface<PushHistoryModel>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {
    firstName: string;
    lastName?: string | null;
    phone: string;
    email: string;
    brand: string;
    model: string;
    year?: number | null;
    description?: string | null;
  };

  export enum ErrorCodes {}
}
