import { CrmLocaleHeaders, HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { NotificationCode, NotificationEntity, NotificationType } from 'backend-entity';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Получение уведомлений c пагинацией
 * basePath: /crm
 * controller: /notification
 * path: ''
 * method: GET
 * fullPath: /crm/notification
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmFindNotificationsEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Notification;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<NotificationEntity>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: FindNotificationsSortingField | string;
    sortDirection?: 'asc' | 'desc';

    notificationId?: string;
    notificationIds?: string[];
    customerId?: string;
    type?: NotificationType;
    code?: NotificationCode;
    viewed?: boolean;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = CrmLocaleHeaders;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}

  export type FindNotificationsSortingField = 'id' | 'type' | 'code' | 'status' | 'viewed' | 'createdAt';
}
