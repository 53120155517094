import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { OrderType } from 'backend-entity';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmFindOrdersResponseDto } from './dtos';

/**
 * ### Получение списка заказов с пагинацией
 * basePath: /crm
 * controller: /rent
 * path: 'orders'
 * method: GET
 * fullPath: /crm/rent/orders
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmFindOrdersEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = 'orders';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<CrmFindOrdersResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: FindOrdersSortingField | string;
    sortDirection?: 'asc' | 'desc';

    rentId?: string;
    customerId?: string;
    type?: OrderType;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}

  export type FindOrdersSortingField =
    | 'id'
    | 'createdAt'
    | 'updatedAt'
    | 'type'
    | 'status'
    | 'totalPrice'
    | 'isFine'
    | 'isAdvance'
    | 'paidAt'
    | 'paidDue';
}
