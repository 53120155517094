import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetRentFinesResponse } from './dtos';

/**
 * ### Получение штрафов ГИБДД аренды
 * basePath: /mobile-bff
 * controller: /rent
 * path: :rentId/fine-orders
 * method: POST
 * fullPath: /mobile-bff/rent/:rentId/fine-orders
 */
export namespace MobileBffGetRentFinesEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Rent;
  export const endPointPath = ':rentId/fines';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Оплата
   */
  export type ResponseData = MobileBffGetRentFinesResponse;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    /** Идентификатор аренды */
    rentId: string;
  };
  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
