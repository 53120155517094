/** Контроллеры срм */
export * from './crm.controller.enum';

export * from './auth';
export * from './user';
export * from './vehicle';
export * from './customer';
export * from './rent';
export * from './storage';
export * from './device';
export * from './catalog';
export * from './tariff';
export * from './maintenance';
export * from './check-list';
export * from './vehicle-inspection';
export * from './scoring';
export * from './notification';
export * from './content';
