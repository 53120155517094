export * from './crm-vehicle-get.response.dto';
export * from './crm-vehicle-post.request.dto';
export * from './crm-vehicle-patch.request.dto';
export * from './crm-vehicle-install-device.request.dto';
export * from './crm-vehicle-remove-device.request.dto';
export * from './crm-vehicle-get-statistics.response.dto';
export * from './crm-vehicle-get-monitoring.response.dto';
export * from './crm-vehicle-get-last-packet.response.dto';
export * from './crm-vehicle-get-monitoring-daily.response.dto';

export * from './crm-vehicle-insurance-create.request.dto';
export * from './crm-vehicle-insurance-create.response.dto';
export * from './crm-vehicle-insurance-patch.request.dto';
export * from './crm-vehicle-insurance-patch.response.dto';
export * from './crm-vehicle-insurance-find.response.dto';
