import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmGetFindCheckListResponseDto } from './dtos';
import { CheckListType } from 'backend-entity';

/**
 * ### Получение чеклистов
 * basePath: /crm
 * controller: /check-list
 * path: '/'
 * method: GET
 * fullPath: /crm/check-list/list
 */

export namespace CrmGetFindCheckListEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.CheckList;
  export const endPointPath = 'list';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список пользователей
   */
  export type ResponseData = PaginationInterface<CrmGetFindCheckListResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * onPage - Размер страницы
   * * page - Запрошенная страница
   * * sortKey - Поле сортировки
   * * sortDirection - Порядок сортировки
   * */
  export interface RequestQueryParams
    extends Record<string, string | number | undefined | boolean | null>,
      PaginationRequest {
    sortField?: string | null;
    sortDirection?: 'asc' | 'desc' | null;
    id?: string | null;
    name?: string | null;
    type?: CheckListType | null;
    project?: string | null;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};
}
