import { ColorEntity } from 'backend-entity';

import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';
import { CrmVehicleColorCreateRequestDto } from './dtos';

/**
 * ### Создание цвета
 * basePath: /crm
 * controller: /vehicle
 * post: ''
 * method: POST
 * fullPath: /crm/vehicle-color
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleColorCreateEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleColor;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = ColorEntity;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {};

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmVehicleColorCreateRequestDto;

  export enum ErrorCodes {}
}
