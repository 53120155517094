import { CustomerEntity } from 'backend-entity';

export interface Card {
  id: string;
  cardMask: string;
  expireDate: string;
}

export type MobileBffGetProfileResponseDto = Pick<CustomerEntity,
  'phone' |
  'email' |
  'firstName' |
  'lastName' |
  'patronymic' |
  'status' |
  'cards'>;
