import { HttpMethod, joinPath, PaginationInterface, PaginationRequest, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmDeviceFindResponseDto } from './dtos/crm-device-find.response.dto';

import { EMisosModel, EDeviceType } from 'backend-entity';

/**
 * ### Получения списка устройств
 * basePath: /crm
 * controller: /device
 * path:
 * method: GET
 * fullPath: /crm/device/
 */
export namespace CrmDeviceFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Device;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список устройств
   */
  export type ResponseData = PaginationInterface<CrmDeviceFindResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса:
   * * onPage - Размер страницы
   * * page - Запрошенная страница
   * */
  export interface RequestQueryParams
    extends Record<string, string | number | undefined | string[] | boolean>,
      PaginationRequest {
    search?: string;
    types?: EDeviceType[];
    models?: EMisosModel[];
    activated?: boolean;
    installed?: boolean;
  }

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
