import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmDeviceMisosPatchRequestDto } from './dtos';
import { CrmDeviceMisosPostResponseDto } from './dtos/crm-device-misos-post.response.dto';

/**
 * ### Обновление устройства Misos
 * basePath: /crm
 * controller: /device
 * path: /misos/:id
 * method: PATCH
 * fullPath: /crm/device/misos/:id
 */
export namespace CrmDeviceMisosPatchEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Device;
  export const endPointPath = 'misos/:id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: Список устройств
   */
  export type ResponseData = CrmDeviceMisosPostResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * * id - ID устройства
   * */
  export interface RequestPathParams extends Record<string, string | number> {
    id: string;
  }

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmDeviceMisosPatchRequestDto;
}
