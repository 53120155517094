export interface CrmVehicleGetLastPacketResponseDto {
  /** Широта */
  latitude: number;
  /** Долгота */
  longitude: number;
  /** Высота, м */
  height: number;
  /**
   * Значение в условных единицах 1 у.е. = Градусы/180 * 128, 0 = восток, против часовой стрелки
   * Например значение 64 = 90 градусов против часовой, т.е. север
   */
  angle: number;
  /** Скорость, км/ч */
  speed: number;
  /** Дата создания пакета данных */
  packetTime: Date;
  /** Процент топлива, от 0 до 100  */
  fuelPercent: number;
  /** Количество топлива, л */
  fuelLiters: number;
  /** Потребление топлива, л */
  fuelConsumption: number;
  /** Пробег, км */
  mileage: number;
  /** Зажигание */
  ignition: boolean;
  /** Стиль вождения */
  drivingStyle: number;
  /** Подключено ли устройство к серверу */
  connected: boolean;
  /** Напряжение аккумулятора, В */
  batteryVoltage: number | null;
  /** Открыта ли дверь */
  isDoorsOpened: boolean;
  /** Открыт ли капот автомобиля */
  isHoodOpened: boolean;
  /** Открыт ли багажник */
  isTrunkOpened: boolean;
  /** Доступные команды для автомобиля */
  allowActions: string[];
  /** Моточасы, ч */
  motoHours?: number;
  /** Заблокирован ли двигатель */
  engineBlock?: boolean;
}
