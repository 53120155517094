import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { AppointmentEntity } from 'backend-entity';
import { CrmControllersEnum } from '../crm.controller.enum';

import { InspectionAppointmentUpdateRequest } from './interfaces';

/**
 * ### Получения списка записей на ТО
 * basePath: /crm
 * controller: /vehicle-inspection
 * path: /appointment/:appointmentId
 * method: PATCH
 * fullPath: /crm/vehicle-inspection/appointment/:appointmentId
 */
export namespace CrmPatchInspectionAppointmentEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleInspection;
  export const endPointPath = 'appointment/:appointmentId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ: Список машин
   */
  export type ResponseData = AppointmentEntity;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    appointmentId: string;
  };

  /** Параметры запроса */
  export type RequestQueryParams = null;

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = InspectionAppointmentUpdateRequest;
}
