import 'quill-emoji';

import { Option } from '@app/shared/interfaces/option.interface';
import { repeat } from '@app/shared/utils/array.utils';
import Quill from 'quill';

const quillAlignClass = Quill.import('attributors/style/align');
const quillBackgroundClass = Quill.import('attributors/style/background');
const quillColorClass = Quill.import('attributors/style/color');
const quillDirectionClass = Quill.import('attributors/style/direction');
const quillFontClass = Quill.import('attributors/style/font');
const quillSizeClass = Quill.import('attributors/style/size');
const blockBlot = Quill.import('blots/block');

export const QUILL_SIZE_OPTIONS: Option[] = repeat(22, (i) => ({
  name: `${14 + i}`,
  value: `${14 + i}px`,
}));

quillSizeClass.whitelist = QUILL_SIZE_OPTIONS.map((i) => i.value);

blockBlot.tagName = 'div';

Quill.register(quillAlignClass, true);
Quill.register(quillBackgroundClass, true);
Quill.register(quillColorClass, true);
Quill.register(quillDirectionClass, true);
Quill.register(quillFontClass, true);
Quill.register(quillSizeClass, true);
Quill.register(blockBlot, true);
