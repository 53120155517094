import { HttpMethod, joinPath } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

/**
 * ### Отмена аренды пользователя
 * basePath: /crm
 * controller: /rent
 * path: ':rentId/finish-blocked'
 * method: POST
 * fullPath: /crm/rent/:rentId/finish-blocked
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmFinishBlockedRentEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = ':rentId/finish-blocked';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.POST;

  /**
   * Ответ
   */
  export type ResponseData = void;
  export type Response = null;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    rentId: string;
  };

  /** Параметры запроса: отсутствуют */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
