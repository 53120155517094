import { HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { MobileBffControllerEnum } from '../mobile-bff.controller.enum';
import { MobileBffGetCatalogItemResponseDto } from './dtos';

/**
 * ### Получение детальной информации по автомобилю из каталога
 * basePath: /mobile-bff
 * controller: /catalog
 * path: ':modificationId/info'
 * method: GET
 * fullPath: /mobile-bff/catalog/:modificationId/info
 */
export namespace MobileBffGetCatalogItemEndpoint {
  export const servicePath = AppServices.MOBILE_BFF;
  export const controller = MobileBffControllerEnum.Catalog;
  export const endPointPath = ':modificationId/info';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Информация об автомобиле из каталога
   */
  export type ResponseData = MobileBffGetCatalogItemResponseDto;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути:
   * vehicleId - идентификатор автомобиля из каталога
   * */
  export type RequestPathParams = {
    modificationId: string;
  };

  /** Параметры запроса: пагинация */
  export type RequestQueryParams = Record<string, string | number | undefined | string[]>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
