import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { RentStatus } from 'backend-entity';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmFindRentsResponseDto } from './dtos';

/**
 * ### Получение списка аренд с пагинацией
 * basePath: /crm
 * controller: /rent
 * path: ''
 * method: GET
 * fullPath: /crm/rent
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmFindRentsEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Rent;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = PaginationInterface<CrmFindRentsResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса */
  export interface RequestQueryParams {
    page: number;
    onPage: number;
    sortField?: string;
    sortDirection?: 'asc' | 'desc';

    rentId?: string;
    customerId?: string;
    vehicleId?: string;
    status?: RentStatus;
    search?: string;
  }

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
