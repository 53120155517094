import { CrmLocaleHeaders, HttpMethod, joinPath, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';

import { DeliveryPoint } from 'backend-entity';

/**
 * ### Получение точки выдачи авто
 * basePath: /crm
 * controller: /maintenance
 * path: 'delivery-point/:id'
 * method: GET
 * fullPath: /crm/maintenance/delivery-point/:id
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmGetDeliveryPointEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Maintenance;
  export const endPointPath = 'delivery-point/:id';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ
   */
  export type ResponseData = DeliveryPoint | null;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = {
    id: string;
  };

  /** Параметры запроса */
  export type RequestQueryParams = Record<string, string | number>;

  /** Заголовки */
  export type RequestHeaders = CrmLocaleHeaders;

  /** Тело запроса: отсутствует */
  export type RequestBody = null;

  export enum ErrorCodes {}
}
