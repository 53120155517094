import { addDays, format } from 'date-fns';

export function repeat<T>(count: number, fillValue: T): T[];
export function repeat<T>(count: number, fillFn: (index: number) => T): T[];
export function repeat<T>(count: number, fillFnOrValue: T | ((index: number) => T)): T[] {
  return new Array(count).fill(null).map((_, index) => {
    if (typeof fillFnOrValue === 'function') {
      return (fillFnOrValue as (index: number) => T)(index);
    } else {
      return fillFnOrValue;
    }
  });
}

export function random(min: number, max: number, roundDigits = 0): number {
  const value = Math.random() * (max - min) + min;
  const roundMultiplier = Math.pow(10, roundDigits);
  return Math.floor(value * roundMultiplier) / roundMultiplier;
}

export function fillPeriod<T extends { date: Date }>(
  minDate: Date,
  maxDate: Date,
  items: T[],
  fillObjectFn: (date: Date) => T,
): T[] {
  if (items.length < 2) {
    return items;
  }

  const clonedItems = [...items];
  const result: T[] = [];

  let tmpDate = minDate;

  do {
    const formattedTmpDate = format(tmpDate, 'dd.MM.yyyy');
    const existingItem = clonedItems.find((i) => format(new Date(i.date), 'dd.MM.yyyy') === formattedTmpDate);

    if (existingItem) {
      result.push(existingItem);
      clonedItems.splice(clonedItems.indexOf(existingItem), 1);
    } else {
      result.push(fillObjectFn(tmpDate));
    }

    tmpDate = addDays(tmpDate, 1);
  } while (tmpDate <= maxDate);

  return result;
}
