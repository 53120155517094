import { VehicleBrandEntity } from 'backend-entity';

import { HttpMethod, joinPath, ResponseType } from '../../../../common';
import { AppServices } from '../../../app-services.enum';
import { CrmControllersEnum } from '../../crm.controller.enum';
import { CrmVehicleBrandPatchRequestDto } from './dtos';

/**
 * ### Обновление информации бренда
 * basePath: /crm
 * controller: /vehicle
 * path: ':brandId'
 * method: PATCH
 * fullPath: /crm/vehicle-brand/:brandId
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace CrmVehicleBrandPatchEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.VehicleBrand;
  export const endPointPath = ':brandId';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.PATCH;

  /**
   * Ответ
   */
  export type ResponseData = VehicleBrandEntity;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути
   * */
  export type RequestPathParams = {
    brandId: string;
  };

  /** Параметры запроса: отсутствует */
  export type RequestQueryParams = null;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = CrmVehicleBrandPatchRequestDto;

  export enum ErrorCodes {}
}
