import { VehicleFilters, VehicleSorting } from 'amqp-contracts';

import {
  HttpMethod,
  joinPath,
  PaginationInterface,
  PaginationRequest,
  ResponseType,
  SortingRequest,
} from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmVehicleGetResponseDto } from './dtos';

/**
 * ### Получения списка машин
 * basePath: /crm
 * controller: /vehicle
 * path:
 * method: GET
 * fullPath: /crm/vehicle/
 */
export namespace CrmVehicleFindEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Vehicle;
  export const endPointPath = '';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = true;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Список машин
   */
  export type ResponseData = PaginationInterface<CrmVehicleGetResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: */
  export interface RequestQueryParams
    extends PaginationRequest,
      SortingRequest<VehicleSorting['field']>,
      VehicleFilters {}

  /** Заголовки: без параметров */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = undefined;
}
