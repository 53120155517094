import { HttpMethod, joinPath, PaginationInterface, ResponseType } from '../../../common';
import { AppServices } from '../../app-services.enum';
import { CrmControllersEnum } from '../crm.controller.enum';
import { CrmGetAvailableCatalogModificationsResponseDto } from './dtos';

/**
 * ### Получение доступных модификаций автомобилей из каталога
 * basePath: /crm
 * controller: /catalog
 * path: 'modifications/available'
 * method: GET
 * fullPath: /crm/catalog/modifications/available
 */
export namespace CrmGetAvailableCatalogModificationsEndpoint {
  export const servicePath = AppServices.CRM;
  export const controller = CrmControllersEnum.Catalog;
  export const endPointPath = 'modifications/available';
  export const getUrl = (params: RequestPathParams): string =>
    joinPath([servicePath, controller, endPointPath], params, true);
  export const auth = false;
  export const type = HttpMethod.GET;

  /**
   * Ответ: Каталог доступных автомобилей
   */
  export type ResponseData = PaginationInterface<CrmGetAvailableCatalogModificationsResponseDto>;
  export type Response = ResponseType<ResponseData>;

  /**
   * Параметры пути: отсутствуют
   * */
  export type RequestPathParams = Record<string, string | number>;

  /** Параметры запроса: пагинация */
  export type RequestQueryParams = Record<string, string | number>;

  /** Заголовки: отсутствуют */
  export type RequestHeaders = Record<string, string | number>;

  /** Тело запроса: отсутствует */
  export type RequestBody = {};

  export enum ErrorCodes {}
}
